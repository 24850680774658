<template>
  <div id="ecommerceForm">
    <header-nav :content="`${$t('pages_router_003')} / ${ $t('pages_router_005')}`" />
    <div class="common-card-body">
      <a-steps :current="1" size="small" class="apply-step">
        <a-step :title="$t('pages_receive_016')" />
        <a-step :title="$t('pages_receive_017')" />
        <a-step :title="$t('pages_receive_018')" />
      </a-steps>
      <div class="apply-desc">{{ $t('pages_receive_019') }}</div>
      <div v-if="remark" class="error-area flex-align">
        <exclamation-circle-outlined class="ic-error" />
        <span>{{ remark }}</span>
      </div>
      <a-form
        name="applyForm"
        class="ant-round-form va-apply"
        label-align="left"
        :label-col="{span: 7}"
        :model="applyForm"
        :rules="applyRules"
        @finish="applySubmit"
      >
        <div class="shop-site flex-align">
          <a-col :span="7"><span class="title">{{ $t('pages_receive_020') }}</span></a-col>
          <div :wrapper-col="{ offset: 7 }" class="flex-align content">
            <img v-if="shopInfo.imgUrl" :src="shopInfo.imgUrl" :style="{width: `${shopInfo.width}px` || ''}">
            <span v-if="['AMAZON', 'EBAY'].includes(shopInfo.pageName)">{{ shopInfo.name }}</span>
            <!-- <img v-if="shopInfo.imgUrl" :src="shopInfo.imgUrl">
            <span v-if="shopInfo.name">{{ shopInfo.name }}</span> -->
          </div>
          <a-button type="link" @click="$router.back(-1)">{{ $t('pages_receive_023') }}</a-button>
        </div>
        <a-form-item v-if="businessType === '10' && businessChildType === '13'" name="salesWebsite" :label="$t('pages_receive_055')">
          <a-input v-model:value="applyForm.salesWebsite" :placeholder="$t('common_text_006')" allow-clear />
        </a-form-item>
        <a-form-item
          v-if="!['AMAZON', 'EBAY'].includes(pageName)"
          name="areaCode"
          :label="$t('pages_receive_025')"
        >
          <a-select
            v-model:value="applyForm.areaCode"
            option-filter-prop="label"
            allow-clear
            show-search
            :placeholder="$t('common_text_005') + $t('pages_receive_025')"
            @change="loadReceiveCurrencies"
          >
            <a-select-option
              v-for="item in areaList"
              :key="item.code"
              :value="item.code"
              :label="item.nameEn + item.name"
            >{{ item.nameEn }} - {{ item.name }}</a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item name="currency" :label="$t('pages_receive_006')">
          <a-select
            v-model:value="applyForm.currency"
            allow-clear
            show-search
            option-filter-prop="label"
            :disabled="['AMAZON', 'EBAY'].includes(pageName)"
            :placeholder="$t('common_text_005') + $t('pages_receive_006')"
          >
            <a-select-option
              v-for="item in curryList"
              :key="item.code"
              :value="item.code"
              :label="`${item.code} - ${item.name}`"
            >
              <img :src="getCountryLogo(item.code)" class="select-img-country">
              <span>{{ item.code }} - {{ item.name }}</span>
            </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item name="tradeSimpleName" :label="$t('pages_receive_005')">
          <a-input v-model:value="applyForm.tradeSimpleName" :placeholder="$t('pages_receive_033')" allow-clear />
        </a-form-item>
        <div v-if="businessType === '10' && businessChildType === '13'">
          <a-form-item name="acquirer" :label="$t('pages_receive_026')">
            <a-radio-group v-model:value="applyForm.acquirer" button-style="solid">
              <a-radio-button
                v-for="item in acquirerOpts"
                :key="item.value"
                :value="item.value"
              >{{ item.name }}</a-radio-button>
            </a-radio-group>
          </a-form-item>
          <div class="flex-align">
            <a-col :span="7" />
            <a-input
              v-if="applyForm.acquirer === 'Other'"
              v-model:value="applyForm.otherAcquirer"
              style="margin: -24px 0 36px 0;  width: 50%"
              :wrapper-col="{ offset: 7 }"
              :placeholder="$t('common_text_006')"
              allow-clear
            />
          </div>
        </div>
        <a-form-item name="shopName" :label="$t('pages_receive_021')">
          <a-input v-model:value="applyForm.shopName" :placeholder="$t('pages_receive_034')" allow-clear />
        </a-form-item>

        <a-form-item :required="true" :label="$t('pages_receive_183')">
          <div style="display: flex">
            <a-form-item
              name="country"
              style="width: 31%; overflow: auto; padding: 1px 2px"
              :required="true"
            >
              <a-select
                v-model:value="applyForm.country"
                allow-clear
                show-search
                option-label-prop="label"
                option-filter-prop="label"
                :placeholder="$t('pages_pay_317')"
              >
                <a-select-option
                  v-for="item in countries"
                  :key="item.id"
                  :value="item.areaCode"
                  :label="`${item.country} - ${item.countryChineseName}`"
                >
                  <span>{{ item.country }} - {{ item.countryChineseName }}</span>
                </a-select-option>
              </a-select>
            </a-form-item>
            <a-form-item
              name="province"
              style="width: 31%; margin-left: 3.5%; padding: 1px 2px"
              :required="true"
            >
              <a-input
                v-model:value="applyForm.province"
                style="height: 32px"
                allow-clear
                :disabled="disabled"
                :placeholder="$t('pages_pay_318')"
              />
            </a-form-item>
            <a-form-item
              name="city"
              style="width: 31%; margin-left: 3.5%; padding: 1px 2px"
              :required="true"
            >
              <a-input
                v-model:value="applyForm.city"
                style="height: 32px"
                allow-clear
                :disabled="disabled"
                :placeholder="$t('pages_pay_319')"
              />
            </a-form-item>
          </div>
          <a-form-item name="address" :rules="{required: true, max: 256}">
            <a-textarea
              v-model:value="applyForm.address"
              class="input-primary"
              allow-clear
              :disabled="disabled"
              :placeholder="$t('pages_receive_184')"
            />
          </a-form-item>
          <a-form-item
            name="zipcode"
            :rules="{required: true, max: 256}"
          >
            <a-input
              v-model:value="applyForm.zipcode"
              allow-clear
              :placeholder="$t('pages_receive_185')"
            />
          </a-form-item>
        </a-form-item>

        <a-form-item v-if="businessChildType !== '13'" name="shopUrl" :label="$t('pages_receive_022')">
          <a-input v-model:value="applyForm.shopUrl" :placeholder="$t('pages_receive_035')" allow-clear />
        </a-form-item>
        <a-form-item name="storeHoldCertify" :label="$t('pages_receive_024')">
          <upload-auto
            ref="uploadRef"
            v-model:file-content="applyForm.storeHoldCertify"
            accept=".jpg,.png,.jepg,.pdf,.bmp,.zip,.rar"
            list-type="picture"
            :explain="businessType === '10' && businessChildType === '13' ? $t('pages_receive_037') : $t('pages_receive_036')"
            :max-count="10"
          />
        </a-form-item>
        <a-form-item name="holderId" :label="$t('pages_receive_166')">
          <div class="flex-align">
            <a-select
              v-model:value="applyForm.holderId"
              allow-clear
              show-search
              :placeholder="$t('common_text_005') + $t('pages_receive_166')"
              @change="loadOpenBanks"
            >
              <a-select-option
                v-for="item in holders"
                :key="item.code"
                :value="item.code"
                :label="item.name"
              >{{ item.name }}</a-select-option>
            </a-select>
            <a-button
              type="primary"
              shape="circle"
              size="small"
              style="margin-left: 12px; font-weight: 600"
              @click="$router.push('/receive/holder_edit')"
            >+</a-button>
          </div>
        </a-form-item>
        <a-form-item :wrapper-col="{ offset: 7 }">
          <a-button
            :disabled="!isAgree"
            :loading="loading"
            html-type="submit"
            type="primary"
            shape="round"
            class="apply-btn"
          >{{ $t('common_text_002') }}</a-button>
          <a-button
            shape="round"
            style="margin-left: 18px"
            class="apply-btn"
            type="primary"
            ghost
            @click="$router.back(-1)"
          >{{ $t('common_text_003') }}</a-button>
        </a-form-item>
        <a-form-item :wrapper-col="{ offset: 7 }" style="margin-top: -12px">
          <a-checkbox v-model:checked="isAgree">
            <span>{{ $t('pages_receive_014') }}</span>
            <span class="use-terms" @click="showTerms">{{ $t('pages_receive_015') }}</span>
          </a-checkbox>
        </a-form-item>
      </a-form>
    </div>
  </div>
</template>
<script>
import { ref, reactive, toRefs, h } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import { _receive } from '@/api/index'
import { _pay } from '@/api'
import i18n from '@/locale/index'
import { Modal, message } from 'ant-design-vue'
import { CloseCircleOutlined } from '@ant-design/icons-vue'
import HeaderNav from '@/components/header-nav'
import UploadAuto from '@/components/upload-auto'
import { getCountryLogo } from '@/util'
import businessList from '@/common/business'
export default {
  name: 'EcommerceForm',
  components: {
    'header-nav': HeaderNav,
    'upload-auto': UploadAuto
  },
  setup() {
    const router = useRouter()
    const route = useRoute()

    const uploadRef = ref(null)
    const state = reactive({
      pageName: route.query.pageName,
      businessType: route.query.businessType,
      businessChildType: route.query.businessChildType,
      remark: '',
      isAgree: false,
      loading: false,
      shopInfo: businessList.find(item => item.value === route.query.businessChildType),
      areaList: [],
      curryList: [],
      holders: [],
      checked: true,
      countries: [],
      applyForm: {
        id: route.query.id || '',
        areaCode: null,
        currency: null,
        shopName: '',
        shopUrl: '',
        salesWebsite: '',
        acquirer: '',
        otherAcquirer: '',
        tradeSimpleName: '',
        onType: 'N',
        auth: 'N',
        storeHoldCertify: '',
        country: '',
        province: '',
        city: '',
        address: '',
        zipcode: '',
        holderId: null
      },
      applyRules: {
        salesWebsite: [{ required: true }],
        areaCode: [{ required: true }],
        currency: [{ required: true }],
        tradeSimpleName: [{ required: true }],
        acquirer: [{ required: true }],
        shopName: [{ required: true }],
        shopUrl: [{ required: true }],
        storeHoldCertify: [{ required: true, message: i18n.global.t('common_text_007') + i18n.global.t('pages_receive_024') }],
        holderId: [{ required: true }],
        country: [{ required: true }],
        province: [{ required: true }],
        city: [{ required: true }],
        address: [{ required: true }],
        zipcode: [{ required: true }]
      },
      acquirerOpts: [
        { name: 'PayPal', value: 'PayPal' },
        { name: 'Stripe', value: 'stripe' },
        { name: 'Adyen', value: 'Adyen' },
        { name: 'Worldpay', value: 'Worldpay' },
        { name: 'Other', value: 'Other' }
      ]
    })
    const showTerms = (e) => {
      e.preventDefault()
      Modal.info({
        title: i18n.global.t('pages_receive_015'),
        icon: null,
        width: 588,
        content: h('div', {
          style: { marginTop: '12px' }
        }, [
          h('div', i18n.global.t('pages_receive_048')),
          h('div', i18n.global.t('pages_receive_049')),
          h('div', i18n.global.t('pages_receive_050'))
        ])
      })
    }
    const loadCountries = async () => {
      const res = await _pay.getOutboundsCountries()
      if (res.data.succ) state.countries = res.data.data
    }

    const readAddress = (data) => {
      const { country, province, city, address, zipcode } = data.businessAddress ? JSON.parse(data.businessAddress) : {}
      state.applyForm.country = country
      state.applyForm.province = province
      state.applyForm.city = city
      state.applyForm.address = address
      state.applyForm.zipcode = zipcode
    }

    const convertAddress = () => {
      const { country, province, city, address, zipcode } = state.applyForm
      return JSON.stringify({
        country,
        province,
        city,
        address,
        zipcode
      })
    }

    const applySubmit = async () => {
      state.loading = true
      const data = Object.assign({}, state.applyForm, {
        businessType: state.businessType,
        businessChildType: state.businessChildType,
        businessAddress: convertAddress()
      })
      if (state.businessType === '00') {
        delete data.acquirer
        delete data.otherAcquirer
        delete data.salesWebsite
        if (state.businessChildType.indexOf('AMAZON') !== -1 || state.businessChildType.indexOf('EBAY') !== -1) data.areaCodeList = state.areaList.map(item => item.code)
      }
      if (state.businessType === '10') {
        delete data.shopUrl
        data.acquirer = state.applyForm.acquirer === 'Other' ? state.applyForm.otherAcquirer : state.applyForm.acquirer
      }
      const res = await _receive.applyToVa(data)
      if (res.data.succ) {
        router.replace('apply_success')
      } else if (res.data.resCode === '130041') {
        Modal.confirm({
          title: i18n.global.t('common_text_015'),
          content: i18n.global.t('pages_holder_059'),
          width: 500,
          icon: h(CloseCircleOutlined, { style: { color: '#ff4d4f' }}),
          okText: i18n.global.t('pages_holder_060'),
          onOk: () => {
            router.push({
              path: '/receive/holder_edit',
              query: { id: state.applyForm.holderId, status: '40' }
            })
          }
        })
      } else {
        message.error(res.data.resMsg)
      }
      state.loading = false
    }
    const loadHolderDetails = async () => {
      const res = await _receive.queryHolderList()
      if (res.data.succ) {
        state.holders = res.data.data
        if (!state.applyForm.holderId && res.data.data[0]) state.applyForm.holderId = res.data.data[0].code
      }
    }
    const loadReceiveAreas = async (businessType, businessChildType) => {
      const res = await _receive.getReceiveAreas({ businessType, businessChildType })
      if (res.data.succ) {
        state.areaList = res.data.data
        if (['AMAZON', 'EBAY'].includes(state.pageName)) loadReceiveCurrencies(...state.areaList.map(item => item.code))
      }
    }
    const loadReceiveCurrencies = async (areaCode, record) => {
      if (typeof record !== 'boolean') state.applyForm.currency = null
      state.curryList = []
      if (areaCode) {
        const { businessType, businessChildType } = state
        const res = await _receive.getReceiveCurrencys({ businessType, businessChildType, areaCode })
        if (res.data.succ) {
          state.curryList = res.data.data
          if (['AMAZON', 'EBAY'].includes(state.pageName)) state.applyForm.currency = state.curryList[0].code
        }
      }
    }
    const loadAccountDetail = async (id) => {
      const res = await _receive.geVaDetail({ id })
      if (res.data.succ) {
        const { data } = res.data
        state.remark = data.remark
        state.applyForm.areaCode = data.areaCode
        state.applyForm.shopName = data.shopName
        state.applyForm.shopUrl = data.shopWebsite
        state.applyForm.salesWebsite = data.saleWebsite
        state.applyForm.tradeSimpleName = data.simpleName
        state.applyForm.storeHoldCertify = data.storeHoldCertify
        state.applyForm.acquirer = data.acquirer
        state.applyForm.holderId = data.holderId ? String(data.holderId) : null
        if (!['PayPal', 'stripe', 'Adyen', 'Worldpay'].includes(data.acquirer)) {
          state.applyForm.acquirer = 'Other'
          state.applyForm.otherAcquirer = data.acquirer
        }
        if (state.businessType === '00') {
          state.applyForm.currency = data.currency
        } else {
          state.applyForm.currency = data.oneCurrency
        }
        if (data.storeHoldCertifies) uploadRef.value.fileListRefresh(data.storeHoldCertifies)
        loadReceiveCurrencies(state.applyForm.areaCode, true)
        loadHolderDetails()
        readAddress(data)
      }
    }
    if (state.applyForm.id) loadAccountDetail(state.applyForm.id)
    else loadHolderDetails()
    loadReceiveAreas(state.businessType, state.businessChildType)
    loadCountries()
    return {
      uploadRef,
      getCountryLogo,
      applySubmit,
      showTerms,
      loadReceiveCurrencies,
      ...toRefs(state)
    }
  }
}
</script>
<style lang="less">
#ecommerceForm {
  .common-card-body {
    padding: 18px 36px;
  }
  .apply-desc {
    font-size: 12px;
    color: #666;
    margin-top: 18px;
    text-align: left;
  }
  .error-area {
    width: 600px;
    padding: 12px 24px;
    border-radius: 48px;
    color: @error-color;
    background: #fef0f0;
    margin-top: 24px;
    text-align: left;
    word-break: break-word;
    .ic-error {
      font-size: 28px;
      margin-right: 12px;
    }
  }
  .va-apply {
    width: 600px;
    margin-top: 24px;
    text-align: left;
    .shop-site {
      margin-bottom: 24px;
      padding-bottom: 24px;
      border-bottom: 1px solid #F1F1F1;
      .title {
        font-weight: 500;
      }
      .content {
        border: 1px solid #DFDFDF;
        border-radius: 30px;
        font-size: 12px;
        justify-content: center;
        overflow: hidden;
        height: 38px;
        width: 178px;
        cursor: pointer;
        img {
          width: 75px;
        }
      }
    }
    .ant-form-item {
      padding-bottom: 12px;
      .apply-btn {
        min-width: 138px;
      }
      .use-terms {
        color: @main-color;
        margin-left: 3px;
      }
    }
  }
}
</style>

