const businessList = [{
  name: '北美站',
  value: 'AMAZON_US',
  pageName: 'AMAZON',
  businessType: '00',
  imgUrl: require('../assets/business/Amazon.png')
}, {
  name: '欧洲站',
  value: 'AMAZON_EU',
  pageName: 'AMAZON',
  businessType: '00',
  imgUrl: require('../assets/business/Amazon.png')
}, {
  name: '英国站',
  value: 'AMAZON_GB',
  pageName: 'AMAZON',
  businessType: '00',
  imgUrl: require('../assets/business/Amazon.png')
}, {
  name: '北美站',
  value: 'EBAY_US',
  pageName: 'EBAY',
  businessType: '00',
  imgUrl: require('../assets/business/Ebay.jpg'),
  width: 60
}, {
  name: '欧洲站',
  value: 'EBAY_EU',
  pageName: 'EBAY',
  businessType: '00',
  imgUrl: require('../assets/business/Ebay.jpg'),
  width: 60
}, {
  name: '英国站',
  value: 'EBAY_GB',
  pageName: 'EBAY',
  businessType: '00',
  imgUrl: require('../assets/business/Ebay.jpg'),
  width: 60
}, {
  name: '印尼站',
  value: 'SHOPEE_IN',
  pageName: 'SHOPEE',
  businessType: '00',
  imgUrl: require('../assets/business/Shopee.png'),
  width: 90
},
{
  name: 'Vinted',
  value: 'VINTED',
  pageName: 'VINTED',
  businessType: '00',
  imgUrl: require('../assets/business/Vinted.jpg'),
  width: 55
},
{
  name: 'Wish',
  value: 'WISH',
  pageName: 'WISH',
  businessType: '00',
  imgUrl: require('../assets/business/Wish.jpg'),
  width: 60
},
{
  name: 'Newegg',
  value: 'NEWEGG',
  pageName: 'NEWEGG',
  businessType: '00',
  imgUrl: require('../assets/business/Newegg.jpg'),
  width: 50
},
{
  name: 'Wayfair',
  value: 'WAYFAIR',
  pageName: 'WAYFAIR',
  businessType: '00',
  imgUrl: require('../assets/business/Wayfair.jpg'),
  width: 60
},
{
  name: 'AliExpress',
  value: 'ALIEXPRESS',
  pageName: 'ALIEXPRESS',
  businessType: '00',
  imgUrl: require('../assets/business/AliExpress.jpg')
},
{
  name: 'MercadoLibre',
  value: 'MERCADOLIBRE',
  pageName: 'MERCADOLIBRE',
  businessType: '00',
  imgUrl: require('../assets/business/MercadoLibre.jpg'),
  width: 70
},
{
  name: 'Etsy',
  value: 'ETSY',
  pageName: 'ETSY',
  businessType: '00',
  imgUrl: require('../assets/business/Etsy.jpg'),
  width: 50
},
{
  name: 'Rakuten',
  value: 'RAKUTEN',
  pageName: 'RAKUTEN',
  businessType: '00',
  imgUrl: require('../assets/business/Rakuten.jpg'),
  width: 65
},
{
  name: 'Cdiscount',
  value: 'CDISCOUNT',
  pageName: 'CDISCOUNT',
  businessType: '00',
  imgUrl: require('../assets/business/Cdiscount.jpg')
}, {
  name: 'Fnac',
  value: 'FNAC',
  pageName: 'FNAC',
  businessType: '00',
  imgUrl: require('../assets/business/Fnac.jpg'),
  width: 65
}, {
  name: 'Kauflandde',
  value: 'KAUFLANDDE',
  pageName: 'KAUFLANDDE',
  businessType: '00',
  imgUrl: require('../assets/business/Kauflandde.jpg')
}, {
  name: 'Darty',
  value: 'DARTY',
  pageName: 'DARTY',
  businessType: '00',
  imgUrl: require('../assets/business/Darty.jpg'),
  width: 50
}, {
  name: 'Otto',
  value: 'OTTO',
  pageName: 'OTTO',
  businessType: '00',
  imgUrl: require('../assets/business/Otto.jpg'),
  width: 50
}, {
  name: 'Lazada',
  value: 'LAZADA',
  pageName: 'LAZADA',
  businessType: '00',
  imgUrl: require('../assets/business/Lazada.jpg')
}, {
  name: 'Mercari',
  value: 'MERCARI',
  pageName: 'MERCARI',
  businessType: '00',
  imgUrl: require('../assets/business/Mercari.png'),
  width: 25
}, {
  name: 'SHOPIFY',
  value: '11',
  pageName: 'SHOPIFY',
  businessType: '10',
  imgUrl: require('../assets/business/Shopify.png'),
  width: 100
}, {
  name: 'SHOPLAZZA',
  value: '12',
  pageName: 'SHOPLAZZA',
  businessType: '10',
  imgUrl: require('../assets/business/Shoplazza.png'),
  width: 95
}, {
  name: '',
  value: '13',
  pageName: '',
  businessType: '10',
  imgUrl: require('../assets/business/Buildself.png'),
  width: 95
}]

export default businessList
